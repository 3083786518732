<template>
    <div>
        <ul class="tab-navs spmg-bottom-db" style="margin-bottom:0px">
            <li class="tn-item current">
                我的消息
            </li>
        </ul>
        <div class="myorder_table" >
            <div class="order_none">
                <p class="order_none_text">没有消息记录</p>
                <p>
                    <router-link :to="{name:'indexPage'}">
                        首页
                    </router-link>
                    <router-link :to="{name:'Mycollection'}">
                        我的收藏
                    </router-link>
                </p>
            </div>
        </div>
        <ul class="conLList" style="display:none">
            <li>
                <span class="conLList_left" @click="dialogVisible=true">这是一条消息标题</span>
                <span class="conLList_right">2021-03-25</span>
            </li>
        </ul>
        <div class="mess_details">
            <el-dialog
                title="消息详情"
                :visible.sync="dialogVisible"
                width="840px"
                :before-close="handleClose"
                :show-close="false">
                <i class="el-icon-close closei" @click="closei()"></i>
                <div class="mess_meban">
                    <p class="mess_name">
                        新产品产品介绍
                    </p>
                    <p class="mess_date">
                        2021-03-25
                    </p>
                    <p class="mess_content">
                        3月9日，兰州海关所属金城海关为海默科技（集团）股份有限公司新备案的“碳钢棒料”进口料件设立加工贸易手册，这是《商务部 海关总署关于调整加工贸易禁止类商品目录的公告》实施以来，兰州海关设立的首本剔除加工贸易禁止类目录涉及商品的手册，也是甘肃省进出口企业首次享受到此项政策红利。
                    </p>
                </div>
            </el-dialog>
        </div>
    </div>
</template>
<script>
export default {
    data () {
        return {
            dialogVisible:false,
        }
    },
    methods: {
        closei(){
            this.dialogVisible = false
        },
        handleClose(done){
            console.log(done)
        }
    }
}
</script>
<style scoped>
@import "./../../styles/Page/Downloadlatest.css";
</style>
<style>
.mess_details .el-dialog{
    min-height: 400px;
}
.mess_details .el-dialog__header{
    padding: 0 0 0 20px;
    height: 50px;
    line-height: 50px;
    font-size: 16px;
    color: #3e3e3f;
    border-bottom: 1px solid #ddd;
}
.mess_details .el-dialog__title{
    font-size: 16px;
    color: #3e3e3f;
}
.mess_details .el-dialog__body{
    padding: 0;
}
</style>